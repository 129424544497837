<template>
  <div>
    <v-card class="pa-2 pt-0 pb-0">
      <v-container>
        <v-row
          no-gutters
          align="center"
          class="mb-3"
        >
          <v-col class="headline">
            Publish Options
          </v-col>

          <v-col cols="auto">
            <v-btn
              small
              fab
              icon
              @click="hidePrefsModal"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            cols="12"
            class="mb-1"
          >
            <v-radio-group
              :id="radioFields.id"
              v-model="radioFields.value"
              class="ma-0"
              hide-details
              @change="handlePublishOptionChange"
            >
              <div
                v-for="(radio, i) in radioFields.items"
                :key="radio.id"
              >
                <v-radio
                  :label="radio.label"
                  :value="i + 1"
                  class="mb-1"
                  color="secondary"
                  on-icon="check_circle"
                />

                <div class="caption pl-8 note mb-3">
                  {{ radio.info }}
                </div>
              </div>
            </v-radio-group>
          </v-col>
          <v-col
            v-if="radioFields.value == 3"
            cols="12"
            class="py-0 my-2"
          >
            <ConnectionSelect
              :id="selectField.id"
              :label="selectField.label"
              :value.sync="selectField.value"
            />
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="mx-1 mt-4"
        >
          <v-btn
            rounded
            light
            color="primary"
            :disabled="
              !radioFields.value ||
                (radioFields.value === 3 && selectField.value.length === 0)
            "
            width="100%"
            @click="handlePublishClick"
          >
            <span v-if="!radioFields.value">Select publish option</span>
            <span v-if="radioFields.value === 1">Publish to all</span>
            <span v-if="radioFields.value === 2">Publish to your network</span>
            <span
              v-if="radioFields.value === 3 && selectField.value.length === 0"
            >Select Connections</span>
            <span
              v-if="radioFields.value === 3 && selectField.value.length > 0"
            >Publish to selected connections</span>
          </v-btn>
          <v-col
            cols="12"
            class="caption my-2 note"
          >
            <div v-if="radioFields.value === 1">
              By publishing you agree to Riverflex’s
              <span><a
                @click="showTerms"
                class="link primary--text"
              >terms &amp; conditions.</a>
              </span>
            </div>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="mx-1 my-2"
          v-if="!isAdmin"
        >
          <v-btn
            rounded
            dark
            color="orange"
            width="100%"
            @click="showSupport"
          >
            Ask riverflex for support
          </v-btn>
          <v-col
            cols="12"
            class="caption my-2 note"
          >
            Let Riverflex manage the opportunity
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-dialog
      v-model="termsDialog"
      :retain-focus="false"
      transition="slide-y-transition"
    >
      <Terms @hide="termsDialog = false" />
    </v-dialog>

    <v-dialog
      v-model="supportDialog"
      :retain-focus="false"
      transition="slide-y-transition"
    >
      <Support
        @hide="supportDialog = false"
        @support="support"
      />
    </v-dialog>
  </div>
</template>
<script>
import Terms from '@/components/core/Terms'
import Support from '@/components/opportunities/create/Support'
import ConnectionSelect from '@/components/core/ConnectionSelect'
import { oppPublishOptionsEvents } from '@/segmentAnalytics/opportunities'

export default {
  props: ['radioFields', 'selectField', 'isAdmin'],
  components: {
    Terms,
    Support,
    ConnectionSelect
  },
  data () {
    return {
      termsDialog: false,
      supportDialog: false
    }
  },
  methods: {
    handlePublishClick () {
      oppPublishOptionsEvents.trackPublish(this.radioFields.value)
      this.$emit('publish')
    },
    handlePublishOptionChange (value) {
      oppPublishOptionsEvents.trackPublishOptionSelect(value)
    },
    support () {
      this.$emit('support')
    },
    hidePrefsModal () {
      oppPublishOptionsEvents.trackClose()
      this.$emit('hide')
    },
    showTerms () {
      oppPublishOptionsEvents.trackTermsAndCondTap()
      this.termsDialog = true
    },
    showSupport () {
      oppPublishOptionsEvents.trackAskForSupport()
      this.supportDialog = true
    }
  }
}
</script>
<style lang="scss" scoped>
.note {
  color: grey;
}
.v-input {
  font-size: 1rem;
}
</style>
