

















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import InfoDialog from '@/components/core/InfoDialog.vue'
import { PURPLE_BG_GRADIENT_TOP } from '@/constants'
import { shareOppCampaignEvents } from '@/segmentAnalytics/opportunities'

@Component({
  components: {
    InfoDialog,
  },
  computed: {
    ...mapGetters(['showShareOpportunityCampaignDialog'])
  }
})
export default class ShareOpportunityCampaignDialog extends Vue {
  contentStyle: string = PURPLE_BG_GRADIENT_TOP

  showShareOpportunityCampaignDialog: boolean

  hideModal () {
    shareOppCampaignEvents.trackClose()
    this.$store.commit('hideShareOpportunityCampaignDialog')
  }

  handleEmailClick () {
    shareOppCampaignEvents.trackEmailClick()
    window.location.href = 'mailto:derek.vanderschyff@riverflex.com'
  }

  handlePostOppClick () {
    shareOppCampaignEvents.trackPostClick()
    this.$emit('postOpp')
  }
}
