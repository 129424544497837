<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          label="Add about section"
          single-line
          solo
          @click="addAbout"
          v-show="about.showAddAbout && !about.content.text"
          append-icon="add"
          hide-details
        />

        <v-textarea
          id="about"
          ref="aboutText"
          v-model="about.content.text"
          :counter="640"
          clearable
          auto-grow
          clear-icon="close"
          label="About"
          class="px-1"
          color="secondary"
          append-icon="edit"
          v-show="!about.showAddAbout || about.content.text"
          @keyup="about.showAboutBtn = true"
          v-trackEvent:focus="{
            event: 'add_about_fill', props: { action: 'edit' }
          }"
          v-trackEvent:blur="{
            event: 'add_about_fill', props: { action: 'close_editing' }
          }"
        />

        <div
          v-show="about.showAboutBtn"
          class="text-left"
        >
          <v-btn
            class=""
            color="primary"
            rounded
            outlined
            :disabled="!about.content.text"
            v-show="!about.showAddAbout || about.content.text"
            @click="updateField"
          >
            <v-icon
              dark
              small
              class="mr-1"
            >
              check
            </v-icon> Save
          </v-btn>
        </div>

        <div
          class="pr-3 pt-2 caption grey--text text--darken-1"
          v-show="about.showAddAbout"
        >
          Adding your background info increases your profile credibility and
          helps the network know what you can do.
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="headline mt-1">
          Contact information
        </div>
      </v-col>
    </v-row>

    <FormFields :fields="this.fields" />

    <v-row class="mt-5 mb-2">
      <v-col>
        <div class="headline">
          Your Résumé/CV
        </div>
      </v-col>
    </v-row>

    <UploadCV
      v-trackEvent:uploadBtnClick="{
        event: 'upload_cv_tap'
      }"
    />
  </div>
</template>

<script>


import UploadCV from '@/components/core/UploadCV'
import FormFields from '@/components/core/FormFields'
import { waitAsync } from '@/utils/common'

export default {
  name: 'ProfileAbout',
  props: { user: { type: Object } },
  components: {
    FormFields,
    UploadCV
  },
  methods: {
    async addAbout () {
      this.about.showAddAbout = false
      await waitAsync(100)
      this.$refs.aboutText.focus()
    },
    updateField () {
      this.about.showAboutBtn = false
      this.$store.dispatch('updateProfile', { about: this.about.content.text })
    }
  },
  data () {
    return {
      about: {
        showAddAbout: true,
        showAboutBtn: false,
        label: 'Add about section',
        content: {
          text: this.user.about
        }
      },
      fields: [
        {
          label: 'Website',
          name: 'website',
          isActive: false,
          value: this.user.website,
          editable: true
        },
        {
          label: 'Linkedin',
          name: 'linkedin',
          isActive: false,
          value: this.user.linkedin,
          editable: true
        },
        {
          label: 'Phone',
          name: 'phone',
          isActive: false,
          value: this.user.phone,
          editable: true
        },
        {
          label: 'Email',
          name: 'email',
          isActive: false,
          value: this.user.email,
          editable: true
        }
      ]
    }
  }
}
</script>
<style lang='scss' scoped>
.v-input ::v-deep .mdi-close {
  font-size: 1.3rem;
}

.v-input ::v-deep textarea {
  font-size: 1rem;
  //color: grey !important;
}
.notice {
  color: grey;
}
</style>
