






































import { mapGetters } from 'vuex'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IUser } from '@/types/models/user.model'
import UserReferralsDialog from '@/components/profile/UserReferralsDialog.vue'

@Component({
  components: {
    UserReferralsDialog,
  },
  computed: {
    ...mapGetters(['isAdmin', 'profileID'])
  }
})
export default class UserReferrals extends Vue {
  @Prop() user: IUser
  @Prop({ default: 'Referrals to the platform' }) title: string

  showReferralsDialog: boolean = false

  isAdmin: boolean
  profileID: number

  get referralsNumber (): number {
    if (!this.user) return 0
    return this.user?.referralsNumber || 0
  }

  get canViewReferrals (): boolean {
    return !!this.referralsNumber && (this.isAdmin || this.profileID === this.user.id)
  }

  handleViewReferralsClick () {
    if (!this.canViewReferrals) return
    this.showReferralsDialog = true
    this.$emit('viewReferralsClicked')
  }
}
