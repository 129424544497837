export const NETWORK_TYPES = {
  UNPUBLISHED: 0,
  ALL: 1,
  PERSONAL_NETWORK: 2,
  SPECIFIC_CONNECTIONS: 3,
  SUPPORT_REQUESTED: 10,
}

export default {
  [NETWORK_TYPES.UNPUBLISHED]: {
    label: 'Not Published',
    class: 'darkPurple'
  },
  [NETWORK_TYPES.ALL]: {
    label: 'Riverflex Members',
    class: 'secondary'
  },
  [NETWORK_TYPES.PERSONAL_NETWORK]: {
    label: 'Personal Network',
    class: 'purple'
  },
  [NETWORK_TYPES.SPECIFIC_CONNECTIONS]: {
    label: 'Specific Connections',
    class: 'purple'
  },
  [NETWORK_TYPES.SUPPORT_REQUESTED]: {
    label: 'Support Requested',
    class: 'appRed'
  }
}