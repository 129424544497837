import { render, staticRenderFns } from "./LoginSlider.vue?vue&type=template&id=bc07604e&scoped=true&"
import script from "./LoginSlider.vue?vue&type=script&lang=ts&"
export * from "./LoginSlider.vue?vue&type=script&lang=ts&"
import style0 from "./LoginSlider.vue?vue&type=style&index=0&id=bc07604e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc07604e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCarousel,VCarouselItem,VImg})
