























































import { Component, Prop, Vue, Mixins, Watch } from 'vue-property-decorator'
import Editor from '@tinymce/tinymce-vue'
import { IRuleFunction } from '@/types/form'
import IsMountedMixin, { IIsMountedMixin } from '@/mixins/IsMounted.mixin'
import colors from '@/assets/theme-colors'

import 'tinymce/tinymce'
import { Editor as EditorClass, RawEditorSettings } from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'

import 'tinymce/plugins/paste'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/image'
import 'tinymce/plugins/table'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/media'
import 'tinymce/plugins/help'
import 'tinymce/plugins/wordcount'

type IHTMLEditor = typeof EditorClass.prototype

const ViewMixins = Mixins(IsMountedMixin)

// https://www.tiny.cloud/docs/integrations/bootstrap/#usingtinymceinabootstrapdialog
document.addEventListener('focusin', (event: FocusEvent) => {
  if ((event.target as Element)?.closest('.tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root')) {
    event.stopImmediatePropagation()
  }
})

/**
 * DOCS:
 * https://www.tiny.cloud/docs/integrations/vue/
 * https://www.tiny.cloud/docs/configure/editor-appearance/
 *
 * Examples of usage
 *
  <HTMLEditor
    class="custom-input"
    label="Description"
    id="description"
    :editor-options="{
      placeholder: 'Hello!'
    }"
    :value.sync="value" // your model in which editor's value is stored
    :characters-limit="120"
  />

  * To tweak toolbar, just pass toolbar to editor-options
  <HTMLEditor
    class="custom-input"
    label="Your field label"
    id="fieldId"
    :editor-options="{
      toolbar: 'undo redo | formatselect | bold italic backcolor'
    }"
  />

  * In future we may come to several defined types of html editor, every each with its own options:
  * For example InlineHTMLEditor ClassicHTMLEditor SimpleHTMLEditor
*/
@Component({
  components: {
    Editor,
  }
})
export default class HTMLEditor extends ViewMixins implements IIsMountedMixin {
  @Prop() id: string
  @Prop() label: string
  @Prop({ required: true }) value: string // your model in which editor stores html code
  @Prop({ default: () => {} }) editorOptions: RawEditorSettings // use this prop to tweak editor appearance, toolbar and other options
  @Prop({ default: 0 }) charactersLimit: number
  @Prop() validationRules: IRuleFunction[] // set of validation rules, are run everytime user make changes

  $refs: {
    htmlEditor: any
  }

  // data
  editorInitOptions: RawEditorSettings = {
    root_name: 'richTextEditor',
    skin: false,
    content_css: ['https://use.typekit.net/kco0ghz.css'],
    menubar: false,
    statusbar: false,
    content_style: `
      body {
        font-family: 'sofia-pro', arial, serif;
        font-size: 13px;
        color: ${colors.primary};
      }
      .tox-tinymce-inline, .tox-menu { z-index: 1000; }
      h1, h2, h3, h4, h5, h6 { margin: 0; }
      p { margin: 0 0 16px 0; }
      a { color: ${colors.primary}; }
      pre {
        background-color: ${colors.beige};
        padding: 5px;
      }
    `,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount',
    ],
    toolbar: `
      undo redo | formatselect | bold italic backcolor
      | alignleft aligncenter alignright alignjustify
      | bullist numlist | link table
      | removeformat
    `,
    block_formats: 'Paragraph=p;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;Preformatted=pre',
    allow_script_urls: false,
    ...this.editorOptions,
  }

  charactersCount: number = 0
  errorMessage: string = ''
  isMounted: boolean
  editorKey = 'editor'

  @Watch('editorOptions')
  onEditorOptionsChanged (newOptions: RawEditorSettings) {
    if (!newOptions) return
    this.editorInitOptions = {
      ...this.editorInitOptions,
      ...newOptions,
    }
  }

  @Watch('value')
  onValueChanged (newValue: string) {
    if (!this.getEditorRawText() && newValue) {
      this.getEditorRef()?.setContent(newValue)
    }
  }

  // computed
  get editorContent (): string {
    return this.value
  }

  set editorContent (value: string) {
    this.$emit('update:value', value)
  }

  get isValid (): boolean {
    return !this.errorMessage
  }

  // methods
  handleTextChange (value: any) {
    const text = this.getEditorRawText()
    this.charactersCount = text.length
    this.validateValue(text)
  }

  handleFocus () {
    this.$emit('focus')
  }

  handleBlur () {
    this.$emit('blur')
  }

  handleShow () {
    this.$emit('show')
  }

  validateValue (text: string) {
    if (!this.validationRules) return
    for (let validate of this.validationRules) {
      const error = validate(text)
      if (typeof error === 'string') {
        this.errorMessage = error
        break
      }
      this.errorMessage = ''
    }
  }

  getEditorRef (): IHTMLEditor|undefined {
    return this.$refs?.htmlEditor?.editor
  }

  getEditorRawText (): string {
    return this.getEditorRef()?.getContent({ format: 'text' }) || ''
  }
}
