<template>
  <div>
    <v-row
      class="mt-5 mb-3 pb-3 no-gutters beige rounded"
      no-gutters
    >
      <v-col class="pl-5 pr-5">
        <v-progress-linear
          :value="profilePercent"
          rounded
          height="25"
          reactive
          class="mt-5"
          color="purple"
          light
        >
          <div class="text-left">
            {{ profilePercent }}%
          </div>
        </v-progress-linear>

        <v-row no-gutters>
          <v-col
            cols="12"
            class="mt-2"
          >
            <div>Status: {{ memberStatusTitle }}</div>
            <div class="caption grey--text text--darken-1" />
          </v-col>

          <v-col cols="auto">
            <v-btn
              color="primary"
              class="apply pb-1 px-0"
              text
              small
              light
              @click="dialog = true"
            >
              What does this mean?
              <v-icon
                dark
                small
              >
                keyboard_arrow_right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      transition="slide-y-transition"
    >
      <v-card class="pa-2 pt-0 pb-5">
        <v-container>
          <v-row
            no-gutters
            align="center"
          >
            <v-col class="headline">
              {{ memberStatusCopy.heading }}
            </v-col>

            <v-col cols="auto">
              <v-btn
                small
                fab
                icon
                @click="dialog = false"
              >
                <v-icon>
                  close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <template v-for="(section, index) in memberStatusCopy.body">
            <v-row :key="index">
              <v-col>
                <div class="title">
                  {{ section.title }}
                </div>

                <div class="body-2 battleship-grey--text">
                  {{ section.body }}
                </div>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dialogContent from '@/assets/member-status-info'

import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      memberStatusCopy: dialogContent
    }
  },
  computed: {
    ...mapGetters(['profilePercent', 'memberStatus']),
    memberStatusTitle (state) {
      return !this.memberStatus ? 'Riverflex member' : 'Riverflex Insider'
    }
  }
}
</script>
