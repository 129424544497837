

























import { Component, Prop, Vue } from 'vue-property-decorator'
import UpdateAvailability from '@/components/core/UpdateAvailability.vue'

@Component({
  components: {
    UpdateAvailability
  }
})
export default class WeeklyAvailability extends Vue {
  @Prop({ default: 'Edit my availability' }) title: string
  @Prop({ default: 'secondary' }) color: string

  // data
  availabilityDialog: boolean = false

  // methods
  handleUpdateAvailabilityClick () {
    this.availabilityDialog = true
  }

  hideModal () {
    this.availabilityDialog = false
  }
}
