<template>
  <v-col
    cols="12"
    class="ma-0 pb-2"
  >
    <div class="text-center line">
      <slot />
    </div>
  </v-col>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.line {
  margin-top: 1em !important;
  line-height: 2em;
  font-size: 1.5em;
  position: relative;
  overflow: hidden;
  text-align: center;

  .media-desktop & {
    margin-top: 2em !important;
    line-height: 3em;
    font-size: 2em;
  }

  .info-icon {
    position: relative;
    bottom: 3px;
  }
}
.line:before,
.line:after {
  content: " ";
  position: absolute;
  top: 50%;
  margin-left: -999em;
  height: 2px;
  width: 998em;
  border-top: 1px solid var(--v-lightGrey-base);
}
.line:after {
  left: auto;
  width: 998em;
  margin: 0 0 0 1em;
}
</style>
