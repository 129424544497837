



















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import InfoDialog from '@/components/core/InfoDialog.vue'
import createOpportunity from '@/components/opportunities/createOpportunity.vue'
import { waitAsync } from '@/utils/common'
import { MODAL_TRANSITION_TIME, PURPLE_BG_GRADIENT_TOP } from '@/constants'
import { postOppCampaignEvents } from '@/segmentAnalytics/dashboard'

@Component({
  components: {
    InfoDialog,
    createOpportunity
  },
  computed: {
    ...mapGetters(['showPostOpportunityCampaignDialog'])
  }
})
export default class PostOpportunityCampaignDialog extends Vue {
  showCreateDialog: boolean = false
  contentStyle = PURPLE_BG_GRADIENT_TOP

  showPostOpportunityCampaignDialog: boolean

  async submitOpp (input: any) {
    this.showCreateDialog = false
    await waitAsync(MODAL_TRANSITION_TIME)
    this.$store.dispatch('upsertOpportunity', input)
  }

  hideModal () {
    postOppCampaignEvents.trackClose()
    this.$store.commit('hidePostOpportunityCampaignDialog')
  }

  handlePostOppClick () {
    postOppCampaignEvents.trackPostJobClick()
    this.showCreateDialog = true
  }
}
