































































import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoDialog from '@/components/core/InfoDialog.vue'
import createOpportunity from '@/components/opportunities/createOpportunity.vue'
import { waitAsync } from '@/utils/common'
import { MODAL_TRANSITION_TIME } from '@/constants'
import { shareOppRewardEvents } from '@/segmentAnalytics/rewards'

@Component({
  components: {
    InfoDialog,
    createOpportunity
  }
})
export default class ShareOppRewardsDialog extends Vue {
  @Prop({ required: true }) show: boolean
  @Prop() contentStyle: string
  
  showCreateDialog: boolean = false

  handlePostOppClick () {
    shareOppRewardEvents.trackPostJob()
    this.showCreateDialog = true
  }

  handleDialogHide () {
    shareOppRewardEvents.trackClose()
    this.$emit('hide')
  }

  handleSupportEmailClick () {
    shareOppRewardEvents.trackEmailForSupport()
  }

  async submitOpp (input: any) {
    this.showCreateDialog = false
    await waitAsync(MODAL_TRANSITION_TIME)
    this.$store.dispatch('upsertOpportunity', input)
  }
}
