<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        Current balance:
      </v-col>
      <v-col
        cols="12"
        class="headline pt-0"
      >
        <span class="purple">&euro;0</span>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col>
        <v-btn
          rounded
          dark
          color="primary"
        >
          Redeem Rewards
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>
<style lang="scss" scoped></style>
