<template>
  <div v-if="opportunity">
    <v-sheet
      class="px-2 mt-0 main-panel"
      min-height="100vh"
    >
      <v-container>
        <v-row
          no-gutters
          align="center"
          class="my-6"
        >
          <v-col class="title">
            Opportunity
          </v-col>

          <v-col class="text-right">
            <v-btn
              v-if="canApply"
              rounded
              color="primary"
              small
              @click="showApplyModal"
            >
              Apply
            </v-btn>

            <v-btn
              v-if="isCreatedByCurrentUser && opportunity.networkType < 10"
              rounded
              color="primary"
              small
              @click="showEditModal"
            >
              Edit
            </v-btn>
          </v-col>

          <v-col cols="auto ml-2">
            <v-btn
              small
              fab
              icon
              @click="hideModal"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            class="headline pb-6"
            style="font-weight:600;"
            cols="12"
          >
            {{ opportunity.headline }}
          </v-col>
          <v-col>
            <div
              class="body-2 network mb-1"
              v-if="opportunity.networkType === ''"
            >
              <span style="color:red">
                !! NETWORK/DURATION FIELDS NEEDED !!
              </span>
            </div>

            <div
              class="body-2 network mb-1"
              v-if="opportunity.networkType === 0"
            >
              <span style="color:grey">
                NOT PUBLISHED
              </span>
            </div>

            <div
              class="body-2 network mb-1"
              v-if="opportunity.networkType && isCreatedByCurrentUser"
            >
              <span
                v-if="opportunity.networkType === 1"
                class="purple--text"
              >
                RIVERFLEX MEMBERS
              </span>
              <span
                v-else-if="opportunity.networkType === 2"
                class="purple--text"
              >
                PERSONAL NETWORK
              </span>
              <span
                v-else-if="opportunity.networkType === 3"
                class="purple--text"
              >
                SPECIFIC CONNECTIONS
              </span>
            </div>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="mb-2"
        >
          <v-col class="body-2">
            <span v-if="isCreatedByCurrentUser">You </span>
            <template v-else-if="opportunity.owner">
              {{ opportunity.owner.name }} published this
            </template>
            <span v-else>Published</span> on the
            <span>{{ opportunity.timestamp | moment("Do MMMM YYYY") }}.</span>
            <span v-if="isOppSupportProvided(opportunity)">
              This opportunity is supported by <span class="purple--text">Riverflex</span>
            </span>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          v-if="isApplied || isDeclined || isInMeeting || isAccepted"
          class="mb-2"
        >
          <v-col class="body-2">
            <template v-if="!!currentUserApplication">
              You applied on the
              <span>{{ currentUserApplication.timestamp | moment("Do MMMM YYYY") }}</span>
            </template>
          </v-col>
          <v-col class="body-2 col-auto">
            <ViewBtn
              title="View application"
              @onClick="viewApplication"
            />
          </v-col>
        </v-row>

        <div v-if="isApplied">
          <v-row
            no-gutters
            v-if="isDeclined"
            class="mb-2"
          >
            <v-col
              class="body-2"
              style="color:red"
            >
              APPLICATION DECLINED
            </v-col>
          </v-row>
        </div>

        <v-row
          no-gutters
          v-if="opportunity.networkType >= 10"
          class="mb-2"
        >
          <v-col
            class="body-2"
            style="color:red"
          >
            SUPPORT REQUESTED
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="title mt-4 mb-3">
            Description
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="mt-1 mb-3">
            <div
              class="opportunity-description"
              v-htmlSanitized="opportunity.description"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="title mt-4 mb-3">
            Role Requirements
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="mt-1">
            <div v-htmlSanitized="opportunity.role" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-simple-table
              dense
              class="view-table"
            >
              <tbody>
                <tr>
                  <td>Industry</td>
                  <td>{{ opportunity.industry }}</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td>
                    {{ opportunity.duration }}
                    <template v-if="opportunity.duration !== permanentDuration">
                      {{ opportunity.timeframe }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{{ opportunity.location }}</td>
                </tr>
                <tr>
                  <td>Day Rate</td>
                  <td v-if="opportunity.rate">
                    {{ opportunity.currency }} {{ opportunity.rate }}
                  </td>
                  <td v-else>
                    TBC
                  </td>
                </tr>
                <tr>
                  <td>Approx. starting date</td>
                  <td>{{ opportunity.startDate | moment("Do MMMM YYYY") }}</td>
                </tr>
                <!-- <tr>
                  <td>Closing date</td>
                  <td>{{ opportunity.endDate | moment("Do MMMM YYYY") }}</td>
                </tr> -->
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row v-if="isAdmin || isCreatedByCurrentUser">
          <v-col>
            <ViewBtn
              title="View applicants"
              @onClick="showApplicants"
            />
          </v-col>
        </v-row>
        <RecruiteeOfferSelect
          v-if="isAdmin"
          :value="opportunityOfferId"
          @selectOffer="handleOfferSelect"
        />

        <v-expansion-panels
          v-if="canViewScreeningQuestions"
          class="screening-questions-panel"
          single
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="px-2">
              <h3 class="title">
                Screening Questions
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-0">
              <ScreeningQuestionsList
                :questions="opportunity.screeningQuestions"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <OpportunityNotificationsTrigger
          v-if="isNotificationsTriggerDislpayed"
          :opportunity="opportunity"
        />
      </v-container>
    </v-sheet>

    <div class="py-3 d-flex justify-space-around bottom-nav">
      <v-btn
        rounded
        outlined
        color="secondary"
        dark
        large
        height="100%"
        active-class="none"
        @click="hideModal"
      >
        CLOSE
      </v-btn>

      <v-btn
        v-if="canShare"
        rounded
        color="secondary"
        dark
        large
        height="100%"
        active-class="none"
        @click="showShareOppModal"
      >
        SHARE
      </v-btn>

      <v-btn
        v-if="canApply"
        rounded
        color="primary"
        large
        height="100%"
        active-class="none"
        @click="showApplyModal"
      >
        APPLY
      </v-btn>

      <v-btn
        v-if="canUnpublish"
        rounded
        color="secondary"
        large
        dark
        height="100%"
        active-class="none"
        @click="showUnPublishModal"
      >
        UNPUBLISH
      </v-btn>

      <v-btn
        v-if="canEditOrPublish"
        rounded
        color="primary"
        large
        height="100%"
        active-class="none"
        @click="showEditModal"
      >
        <span v-if="opportunity.networkType !== 0">EDIT</span>
        <span v-else>EDIT / PUBLISH</span>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import ViewBtn from '@/components/core/ViewBtn'
import OpportunityNotificationsTrigger from '@/components/opportunities/OpportunityNotificationsTrigger.vue'
import { PERMANENT_DURATION } from '@/constants'
import RecruiteeOfferSelect from '@/components/opportunities/create/RecruiteeOfferSelect.vue'
import ScreeningQuestionsList from '@/components/screeningQuestions/ScreeningQuestionsList.vue'
import { oppScreenEvents } from '@/segmentAnalytics/opportunities'

export default {
  props: ['opportunity'],
  components: {
    ViewBtn,
    OpportunityNotificationsTrigger,
    RecruiteeOfferSelect,
    ScreeningQuestionsList
  },
  data () {
    return {
      permanentDuration: PERMANENT_DURATION
    }
  },
  computed: {
    ...mapState({
      publishedOpps: state => state.opportunities.published,
      applications: state => state.applications.applications,
    }),
    ...mapGetters([
      'profileID',
      'isAppApplied',
      'isAppDeclined',
      'isAppInMeeting',
      'isAppAccepted',
      'isAdmin',
      'applicationByOppId',
      'isOppSupportProvided'
    ]),
    opportunityId () {
      return this.opportunity.id
    },
    opportunityOfferId () {
      return this.opportunity.offerId
    },
    isCreatedByCurrentUser () {
      return this.opportunity?.owner ? this.opportunity.owner?.id === this.profileID : false
    },
    isApplied () {
      return this.isAppApplied(this.opportunityId)
    },
    isDeclined () {
      return this.isAppDeclined(this.opportunityId)
    },
    isInMeeting () {
      return this.isAppInMeeting(this.opportunityId)
    },
    isAccepted () {
      return this.isAppAccepted(this.opportunityId)
    },
    canApply () {
      return !this.isCreatedByCurrentUser && !this.isAdmin && !this.applicationByOppId(this.opportunityId)
    },
    canEditOrPublish () {
      return this.isAdmin || (this.isCreatedByCurrentUser && this.opportunity.networkType < 10)
    },
    canUnpublish () {
      return this.isAdmin || (this.isCreatedByCurrentUser && this.opportunity.networkType !== 0 && this.opportunity.networkType < 10)
    },
    canShare () {
      return !!this.opportunity.shareable || this.isCreatedByCurrentUser
    },
    currentUserApplication () {
      return this.applicationByOppId(this.opportunityId)
    },
    isNotificationsTriggerDislpayed () {
      return this.isAdmin && !!localStorage.getItem('display_opp_notifications_triggers')
    },
    canViewScreeningQuestions () {
      if (this.isCreatedByCurrentUser || this.isAdmin) {
        return !!this.opportunity?.screeningQuestions?.length
      }
      return false
    },
  },
  methods: {
    hideModal () {
      oppScreenEvents.trackClose()
      this.$emit('hide')
    },
    showApplyModal () {
      oppScreenEvents.trackApplyTap()
      this.$emit('apply')
    },
    showEditModal () {
      oppScreenEvents.trackEditTap()
      this.$emit('edit', this.opportunity.id)
    },
    showUnPublishModal () {
      oppScreenEvents.trackUnpublishTap()
      this.$emit('unpublish')
    },
    viewApplication () {
      oppScreenEvents.trackViewApplicantionTap()
      this.$emit('viewApp', this.profileID)
    },
    showShareOppModal () {
      oppScreenEvents.trackShareTap()
      this.$emit('shareOpp')
    },
    showApplicants () {
      oppScreenEvents.trackViewApplicantsTap()
      this.$emit('showApplicants', this.opportunity)
    },
    handleOfferSelect (value) {
      if (!this.isAdmin || !this.opportunityId) return
      this.$store.dispatch('upsertOpportunity', {
        id: this.opportunityId,
        input: { offerId: value }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
// .title{
//     color: grey;
// }
.view-table {
  td {
    padding: 7px 0;
    border-bottom: none !important;
  }
  tr {
    td:first-of-type {
      color: grey;
      min-width: 100px;
      padding-right: 20px;
      vertical-align: top;
    }
    td:last-of-type {
      text-align: right;
    }
  }
}
.main-panel {
  padding-bottom: 60px !important;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.screening-questions-panel {
  z-index: auto;
}

.opportunity-description {
  ::v-deep iframe {
    display: block;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
    height: 30vh;
  }
}
</style>
