<template>
  <div>
    <v-card
      v-if="!!application"
      class="pa-2 pt-0 pb-0"
      min-height="100vh"
    >
      <v-container>
        <v-row
          no-gutters
          align="center"
          class="mb-3"
        >
          <v-col class="headline ml-1">
            Application
          </v-col>

          <v-col cols="auto">
            <v-btn
              small
              fab
              icon
              @click="hideModal"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-if="this.opportunity"
            class="py-0 px-4 headline"
          >
            <strong>{{ opportunity.headline }}</strong>
          </v-col>
        </v-row>

        <v-row v-if="applicant">
          <v-col class="py-0">
            <applicationProfile :profile="applicant" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pb-0">
            <v-simple-table
              dense
              class="view-table"
            >
              <tbody>
                <tr>
                  <td>Available from</td>
                  <td class="">
                    {{ application.availableFrom | moment("Do MMMM YYYY") }}
                  </td>
                </tr>
                <tr v-if="!!application.cv">
                  <td>Applicant CV</td>
                  <td class="">
                    <v-btn
                      rounded
                      color="secondary"
                      x-small
                      download
                      :href="application.cv"
                      @click="handleDownloadClick"
                    >
                      <span>{{ cvName }}</span>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="title pb-0">
            Personal message from applicant:
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span
              v-if="!application.message"
            >{{ applicant.name }} did not leave a message</span>
            {{ application.message }}
          </v-col>
        </v-row>

        <ScreeningQuestionsAnswers :answers="application.answers" />
      </v-container>
    </v-card>

    <div
      class="py-3 d-flex justify-space-around bottom-nav"
      v-if="!isCurrentUserProfile && !oppHasAcceptedAplicant"
    >
      <v-btn
        v-if="canScheduleMeeting"
        rounded
        large
        color="primary"
        height="100%"
        active-class="none"
        @click="scheduleAppMeeting"
      >
        SCHEDULE MEETING
      </v-btn>
      <v-btn
        v-if="canConfirm"
        rounded
        large
        class="white--text"
        color="secondary"
        height="100%"
        active-class="none"
        @click="confirmApp"
      >
        CONFIRM
      </v-btn>

      <v-btn
        v-if="canDecline"
        rounded
        dark
        large
        color="secondary"
        height="100%"
        active-class="none"
        @click="declineApp"
      >
        DECLINE
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import applicationProfile from '@/components/opportunities/applicants/applicationProfile'
import ScreeningQuestionsAnswers from '@/components/screeningQuestions/ScreeningQuestionsAnswers.vue'
import APP_STATUSES from '@/assets/application-statuses'
import { getFileNameFromUrl } from '@/utils/vuex'
import { oppApplicantionEvents } from '@/segmentAnalytics/opportunities'

export default {
  components: {
    applicationProfile,
    ScreeningQuestionsAnswers,
  },
  props: ['application', 'opportunity', 'applicant'],
  computed: {
    ...mapState({
      applications: state => state.applications.applications,
    }),
    ...mapGetters([
      'profileID',
      'isAppApplied',
      'isAppDeclined',
      'isAppInMeeting',
      'isAppAccepted'
    ]),
    opportunityId () {
      return this.application ? this.application.opportunity : null
    },
    applicantId () {
      return this.application ? this.application.user : null
    },
    oppHasAcceptedAplicant () {
      return !!this.applications.find(app => app.opportunity === this.opportunityId && app.status === APP_STATUSES.ACCEPTED)
    },
    canScheduleMeeting (state) {
      return this.isAppApplied(this.opportunityId, this.applicantId)
    },
    canDecline (state) {
      return !this.isAppDeclined(this.opportunityId, this.applicantId) && !this.isAppAccepted(this.opportunityId, this.applicantId)
    },
    canConfirm (state) {
      return this.isAppInMeeting(this.opportunityId, this.applicantId)
    },
    isCurrentUserProfile () {
      return this.applicantId === this.profileID
    },
    cvName () {
      if (!this.application?.cv) return ''
      return getFileNameFromUrl(this.application.cv)
    }
  },
  methods: {
    handleDownloadClick () {
      oppApplicantionEvents.trackDownloadCv()
    },
    hideModal () {
      oppApplicantionEvents.trackClose()
      this.$emit('hide')
    },
    scheduleAppMeeting () {
      oppApplicantionEvents.trackScheduleMeeting()
      this.$emit('meeting')
    },
    declineApp () {
      oppApplicantionEvents.trackDecline()
      this.$emit('decline')
    },
    confirmApp () {
      oppApplicantionEvents.trackConfirm()
      this.$emit('confirm')
    }
  }
}
</script>
<style lang="scss" scoped>
.view-table {
  td {
    padding: 7px 0;
    border-bottom: none !important;
  }
  tr {
    td:first-of-type {
      // color: grey;
      min-width: 100px;
      padding-right: 20px;
      vertical-align: top;
    }
    td:last-of-type {
      text-align: right;
    }
  }
}
.main-panel {
  padding-bottom: 60px !important;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
</style>
