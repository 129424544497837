import _Vue from 'vue'
import Vuetify from 'vuetify/lib'

export interface IMediaQUery {
  isDesktop: boolean
  isLargeDesktop: boolean
  tabletsAndDown: boolean
  mobilesAndDown: boolean
  isExtraSmall: boolean
  breakpointName: string
  isDektopHeight: boolean
}

interface PluginOptions {
  vuetify: Vuetify
}

class MediaQuery implements IMediaQUery {
  private breakpoint: Vuetify['framework']['breakpoint'];
  private minDesktopHeight: number = 870

  constructor (vuetify: Vuetify) {
    this.breakpoint = vuetify.framework.breakpoint
  }

  public get isDesktop (): boolean {
    const { lgAndUp } = this.breakpoint
    return lgAndUp
  }

  public get isLargeDesktop (): boolean {
    const { lgAndUp, height } = this.breakpoint
    return lgAndUp && height > this.minDesktopHeight
  }

  public get tabletsAndDown (): boolean {
    const { mdAndDown } = this.breakpoint
    return mdAndDown
  }

  public get mobilesAndDown (): boolean {
    const { smAndDown } = this.breakpoint
    return smAndDown
  }

  public get isExtraSmall (): boolean {
    return this.breakpoint.xsOnly
  }

  public get breakpointName (): string {
    return this.breakpoint.name
  }

  public get isDektopHeight (): boolean {
    return this.breakpoint.height >= this.minDesktopHeight
  }
}

export default {
  install (Vue: typeof _Vue, options: PluginOptions) {
    Vue.prototype.$mediaQuery = new MediaQuery(options.vuetify)
  }
}
