export default {
  primary: '#000000',
  secondary: '#868686',
  error: '#b71c1c',
  black: '#000000',
  white: '#ffffff',
  lightBeige: '#F7ECDF',
  beige: '#E8DDCF',
  grey: '#868686',
  lightGrey: '#CFCFCF',
  purple: '#8E59FF',
  appRed: '#F44336',
  orange: '#ff9800',
  darkPurple: '#3B1378',
  accent: '#8E59FF',
  info: '#868686',
}
