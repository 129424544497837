














































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import ApiStatus from './components/core/ApiStatus.vue'
import Drawer from '@/components/core/Drawer.vue'
import TopBar from '@/components/core/TopBar.vue'
import BottomNav from '@/components/core/BottomNav.vue'
import LoginSlider from '@/components/core/LoginSlider.vue'
import AddToHomeDialog from '@/components/core/AddToHomeDialog.vue'
import NetworkStatus from '@/components/core/NetworkStatus.vue'
import NewVersionAvailable from '@/components/core/NewVersionAvailable.vue'
import ErrorToast from '@/components/core/ErrorToast.vue'
import AuthService from '@/services/auth.service'
import { IAuthState } from '@/types/api'
import InfoDialog from '@/components/core/InfoDialog.vue'
import GDPRAgreement from '@/components/gdpr/GDPRAgreement.vue'

@Component({
  components: {
    ApiStatus,
    Drawer,
    TopBar,
    BottomNav,
    LoginSlider,
    AddToHomeDialog,
    NetworkStatus,
    NewVersionAvailable,
    ErrorToast,
    InfoDialog,
    GDPRAgreement,
  },
  computed: {
    ...mapGetters(['profileID', 'loggedOut', 'gdprAccepted', 'isNewUser']),
  }
})
export default class App extends Vue {
  name = 'App'

  notLogged: boolean = false
  publicRouteNames: string[] = ['publicOpportunities']

  // computed
  profileID?: number
  loggedOut: boolean
  gdprAccepted: boolean
  isNewUser: boolean

  get appHeightStyle () {
    return `height: ${this.$vuetify.breakpoint.height}px`
  }

  get isAppDisplayed (): boolean {
    return !!this.profileID || this.isPublicRoute
  }

  get isLoginScreenDisplayed (): boolean {
    return !this.isPublicRoute && (this.notLogged || this.loggedOut)
  }

  get isPublicRoute (): boolean {
    return this.publicRouteNames.includes(this.$route.name || '')
  }

  get showGDPRDialog (): boolean {
    return !this.gdprAccepted && !this.isNewUser && !!this.profileID
  }

  // lifecycle hooks
  async created () {
    window.addEventListener('beforeinstallprompt', (e: any) => {
      e.preventDefault()
      console.log('beforeinstallprompt event fired')
      window.$installAppDeferredPrompt = e as BeforeInstallPromptEvent
      this.$store.commit('setCanInstallPWA', true)
    })

    window.addEventListener('appinstalled', (evt) => {
      console.log('pwa installed')
    })

    const session = this.$route.name === 'callback' ?
      await AuthService.handleRedirectCallback() :
      await AuthService.session

    if (session) {
      this.$store.dispatch('login', session)
    } else {
      this.notLogged = true
    }
  }

  // methods
  login () {
    const authState = this.getAuthState()
    AuthService.login(authState)
  }

  logout () {
    this.$store.dispatch('logout')
  }

  getAuthState (): string|undefined {
    let authState: IAuthState
    if (this.$route.name === 'migrate') {
      authState = {
        migrantKey: this.$route.params.migrantKey
      }
    } else if (this.$route.name === 'connect') {
      authState = {
        redirectTo: this.$route.fullPath,
        connectId: this.$route.params.id,
        oppId: (this.$route.query as { opp?: string }).opp
      }
    } else if (this.$route.path === '/') {
      authState = { redirectTo: '/home' }
    } else {
      authState = {
        redirectTo: this.$route.fullPath
      }
    }
    return authState ? JSON.stringify(authState) : undefined
  }
}
