









































import { mapGetters } from 'vuex'
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'

import Greetings from '@/components/core/Greetings.vue'
import Header from '@/components/profile/Header.vue'
import Progress from '@/components/profile/Progress.vue'
import About from '@/components/profile/About.vue'
import Preferences from '@/components/profile/Preferences.vue'
import MyConnections from '@/components/profile/MyConnections.vue'
// import ContactPreferences from '@/components/profile/ContactPreferences.vue'
import UserSubscriptions from '@/components/profile/UserSubscriptions.vue'
import InfoDialog from '@/components/core/InfoDialog.vue'
import UserReferralsNumber from '@/components/profile/UserReferralsNumber.vue'
import LegacyPageContent from '@/components/layouts/LegacyPageContent.vue'

import ShowInfoDialogMixin, { IShowDialogMixin } from '@/mixins/ShowInfoDialog.mixin'
import { IUser } from '@/types/models/user.model'
import { trackHelpBtnTap } from '@/segmentAnalytics/common'

@Component({
  components: {
    Greetings,
    Header,
    Progress,
    About,
    Preferences,
    MyConnections,
    UserSubscriptions,
    InfoDialog,
    UserReferralsNumber,
    LegacyPageContent
    // ContactPreferences
  },
  computed: {
    ...mapGetters(['profile'])
  }
})
export default class Profile extends Mixins(ShowInfoDialogMixin) implements IShowDialogMixin {
  @Prop() hasProfile: boolean

  // data
  pageName: string = 'profile'
  showInfoDialog: boolean = false

  // computed
  // mapGetters
  profile: IUser|null

  // lifecycle hooks
  beforeDestroy () {
    this.$store.dispatch('sendProfileUpdateStats')
  }

  handleInfoIconClick () {
    trackHelpBtnTap('profile')
    this.showInfoDialog = true
  }
}
