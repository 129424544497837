import Vue from 'vue'
import Router, { Route, RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Profile from '@/views/Profile.vue'
import Opportunities from '@/views/Opportunities.vue'
import AdminOpportunities from '@/views/AdminOpportunities.vue'
import Connections from '@/views/Connections.vue'
import Rewards from '@/views/Rewards.vue'
import Callback from '@/views/Callback.vue'
import ConnectReferral from '@/views/ConnectReferral.vue'
import Faq from '@/views/Faq.vue'
import Reports from '@/views/Reports.vue'
import AdminSettings from '@/views/AdminSettings.vue'
import BusinessDetails from '@/views/BusinessDetails.vue'

// import AuthService from '@/services/auth.service'
import store from '@/store'
import { trackPageScreen } from '@/segmentAnalytics/core'

export enum ProfilePageSection {
  availability = 'availability',
}

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'login',
    component: Home,
    meta: {
      eventName: 'welcome_screen'
    }
  },
  {
    path: '/home',
    name: 'dashboard',
    component: Home,
    meta: {
      eventName: 'home_screen'
    }
  },
  {
    path: '/migrate/:migrantKey',
    name: 'migrate',
    component: Home,
  },
  {
    path: '/profile/:section?',
    name: 'profile',
    component: Profile,
    meta: {
      eventName: 'profile_screen'
    }
  },
  {
    path: '/opportunities/:id?',
    name: 'opportunities',
    component: Opportunities,
    meta: {
      eventName: 'opportunities_screen'
    }
  },
  {
    path: '/current-opportunities/:id?',
    name: 'publicOpportunities',
    component: Opportunities,
    meta: {
      eventName: 'public_opportunities_screen'
    }
  },
  {
    path: '/admin/opportunities/:id?',
    name: 'adminOpportunities',
    component: AdminOpportunities,
    meta: {
      eventName: 'admin_opportunities_screen'
    }
  },
  {
    path: '/connections/:id?',
    name: 'connections',
    component: Connections,
    meta: {
      eventName: 'connections_screen'
    }
  },
  // {
  //   path: '/rewards',
  //   name: 'rewards',
  //   component: Rewards,
  //   meta: {
  //     requiresAuth: true,
  //     eventName: 'rewards_screen'
  //   }
  // },
  {
    path: '/connect/:id',
    name: 'connect',
    component: ConnectReferral,
  },
  {
    path: '/callback',
    name: 'callback',
    component: Callback
  },
  {
    path: '/riverflex',
    beforeEnter (to, from, next) {
      window.location.href = 'https://riverflex.com'
    }
  },
  {
    path: '/faq/:faqName?',
    name: 'faq',
    component: Faq,
    meta: {
      eventName: 'faq_screen'
    }
  },
  {
    path: '/admin/reports',
    name: 'reports',
    component: Reports,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/settings',
    name: 'settings',
    component: AdminSettings,
    meta: { requiresAdmin: true }
  },
  {
    path: '/business-details',
    name: 'businessDetails',
    component: BusinessDetails,
  }
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "./views/About.vue")
  // },

]

Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  if (!store.getters.authenticated) return next()

  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  if (requiresAdmin && !store.getters.isAdmin) return next('/')

  next()
})

router.afterEach((to, from) => {
  if (from.name === to.name) return
  const pageEventName = to.meta?.eventName
  if (!pageEventName) return
  trackPageScreen(pageEventName)
})

export default router
